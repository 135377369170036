
import { Component, Vue } from 'vue-property-decorator'
import { Device } from '../../types/insectMonitor'
@Component({
  name: 'DetailList'
})
export default class DetailList extends Vue {
  private info = {
    deviceNumber: ''
  }

  private loading = false
  private btnDisable = false
  private dataList: Array<Device> = []
  page = 1
  size = 10
  total = 0

  get projectId () {
    return this.$route.params.projectId as string
  }

  created () {
    this.getData()
  }

  // 获取列表数据
  getData () {
    this.loading = true
    this.$axios.get<{ total: number; list: Array<Device> }>(this.$apis.insectMonitor.selectInsectPage, {
      ...this.info,
      projectId: this.projectId,
      page: this.page,
      size: this.size
    }).then(res => {
      this.total = res.total || 0
      this.dataList = res.list || []
      this.dataList.forEach(item => {
        item.switchShow = item.functionEnable && item.functionEnable[0] ? item.functionEnable[0].typeCode : '1'
      })
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  toDetail (deviceId: string) {
    this.$router.push({ name: 'insectMonitorDevDetail', params: { projectId: this.projectId, deviceId: deviceId } })
  }

  switchChange (row: any) {
    // 虫情开关控制设备状态 1 在线、2 离线
    const txt = row.deviceStatus === '1' ? '关机' : '开机'
    this.$confirm('确认' + txt + '吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.btnDisable = true
      this.$axios.post(this.$apis.insectMonitor.updateInsectSwitch, {
        deviceId: row.deviceId,
        deviceNumber: row.deviceNumber,
        deviceStatus: row.deviceStatus
      }).then(() => {
        this.$message.success(txt + '成功')
        this.onSearch()
      }).finally(() => {
        this.btnDisable = false
      })
    }).catch((err) => {
      console.log(err)
    })
  }
}
